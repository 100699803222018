import {
  IUser,
  IBaseModule,
  IUserActivity,
  IUserSearchParam,
  IUserResult,
  ISearchResult,
  IRole,
  IChangePassword,
} from "@/store/common/Types";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations, Endpoints } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class UserModule extends VuexModule implements IBaseModule {
  errors = {};
  searchResult = {} as ISearchResult<IUserResult>;
  activeUser = {} as IUser;
  userId = {};
  allRoles = {} as Array<IRole>;
  originalRoles = {} as Array<string>;
  validKeyFlag = false;

  /**
   * Get user errors
   * @returns array
   */
  get getUserErrors() {
    return this.errors;
  }

  /**
   * Get newly-created user ID
   * @returns string
   */
  get getUserId() {
    return this.userId;
  }

  /**
   * Get search result
   * @returns IUserResult
   */
  get getUserSearchResult(): ISearchResult<IUserResult> {
    return this.searchResult;
  }

  /**
   * Get user
   * @returns IUser
   */
  get getUser(): IUser {
    return this.activeUser;
  }

  /**
   * Get all roles
   * @returns Array<IRole>
   */
  get getAllRoles(): Array<IRole> {
    return this.allRoles;
  }

  /**
   * Get valid key flag
   * @returns boolean
   */
  get getValidKeyFlag(): boolean {
    return this.validKeyFlag;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_USER_ID](userId: string) {
    this.userId = userId;
  }

  @Mutation
  [Mutations.SET_USER_SEARCH_RESULT](items: ISearchResult<IUserResult>) {
    this.searchResult = items;

    //set CRM values
    for (var j = 0; j < this.searchResult.items.length; j++) {
      this.searchResult.items[j].hasCRM = this.searchResult.items[j]
        .sugarCrmContactId
        ? true
        : false;
    }
  }

  @Mutation
  [Mutations.SET_USER](user: IUser) {
    this.activeUser = user;
    if (user) {
      this.activeUser.hasCRM = user.sugarCrmContactId ? true : false;
    }
  }

  @Mutation
  [Mutations.SET_ALL_ROLES](roles: Array<IRole>) {
    this.allRoles = roles;
  }

  @Mutation
  [Mutations.SET_USER_ROLES](roles: Array<IRole>) {
    let user = this.activeUser;
    user.roles = roles.map((role) => role.roleId);

    this.activeUser = user;
    this.originalRoles = roles.map((role) => role.roleId);
  }

  @Mutation
  [Mutations.SET_KEY_VALID_FLAG](flag: boolean) {
    this.validKeyFlag = flag;
  }

  @Action
  [Actions.LOAD_USER](userId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Users, userId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading the user.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.LOAD_USER_ROLES](user: IUser) {
    if (user) {
      ApiService.setHeader();
      return ApiService.get(Endpoints.Users, user.userId + "/roles")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_ROLES, data);
        })
        .catch(({ response }) => {
          if (!response) {
            this.context.commit(Mutations.SET_ERROR, [
              "There was an error loading the user roles.",
            ]);
            return;
          }
          if (response.data && response.data.errors) {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          }
        });
    }
  }

  @Action
  [Actions.LOAD_ALL_ROLES]() {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Roles, "?page=0")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_ROLES, data.items);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading roles.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.CREATE_USER](user: IUser) {
    ApiService.setHeader();
    const req = {
      firstName: user.firstName,
      lastName: user.lastName,
      emailAddress: user.emailAddress,
      phoneNumber: null,
      password: user.password,
      isWebAccessEnabled: user.isWebAccessEnabled,
      isSubscribedToEnergyFacts: user.isSubscribedToEnergyFacts,
      ignoreDownloadLimit: user.ignoreDownloadLimit,
      distributionFull: user.distributionFull,
      distributionNoLinks: user.distributionNoLinks,
      canViewOlderDocuments: user.canViewOlderDocuments,
    } as any;
    return ApiService.post(Endpoints.Users, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_ID, data);
        this.context.commit(Mutations.SET_ERROR, {});
        for (var i = 0; i < user.roles.length; i++) {
          ApiService.post(
            Endpoints.Roles + "/" + user.roles[i] + "/users/" + this.userId,
            {}
          );
        }
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error creating the user.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.UPDATE_USER](user: IUser) {
    ApiService.setHeader();
    const req = {
      firstName: {
        op: "Replace",
        value: user.firstName,
      },
      lastName: {
        op: "Replace",
        value: user.lastName,
      },
      emailAddress: {
        op: "Replace",
        value: user.emailAddress,
      },
      isWebAccessEnabled: {
        op: "Replace",
        value: user.isWebAccessEnabled,
      },
      isSubscribedToEnergyFacts: {
        op: "Replace",
        value: user.isSubscribedToEnergyFacts,
      },
      ignoreDownloadLimit: {
        op: "Replace",
        value: user.ignoreDownloadLimit,
      },
      distributionFull: {
        op: "Replace",
        value: user.distributionFull,
      },
      distributionNoLinks: {
        op: "Replace",
        value: user.distributionNoLinks,
      },
      canViewOlderDocuments: {
        op: "Replace",
        value: user.canViewOlderDocuments,
      }
    } as any;

    for (var i = 0; i < this.originalRoles.length; i++) {
      if (!user.roles.includes(this.originalRoles[i])) {
        ApiService.delete(
          Endpoints.Roles +
            "/" +
            this.originalRoles[i] +
            "/users/" +
            user.userId
        );
      }
    }

    for (var i = 0; i < user.roles.length; i++) {
      if (!this.originalRoles.includes(user.roles[i])) {
        ApiService.post(
          Endpoints.Roles + "/" + user.roles[i] + "/users/" + user.userId,
          {}
        );
      }
    }

    return ApiService.patch(Endpoints.Users + "/" + user.userId, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error updating the user.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.DELETE_USER](userId: string) {
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Users, userId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error deleting the user.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.CHANGE_PASSWORD](user: IChangePassword) {
    ApiService.setHeader();
    const req = {
      password: {
        op: "Replace",
        value: user.password,
      },
    } as any;

    return ApiService.patch(Endpoints.Users + "/" + user.userId, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error updating the user password.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  async [Actions.VALIDATE_KEY](key: string) {
    ApiService.setHeader();
    try {
      const query = "forgot/reset?key=" + key;
      const meta = await ApiService.head(Endpoints.Users, query);
      if (meta.headers && meta.headers["x-reset-key-valid"]) {
        const result = meta.headers["x-reset-key-valid"] == "True";
        this.context.commit(Mutations.SET_KEY_VALID_FLAG, result);
      }
    } catch (err) {
      this.context.commit(Mutations.SET_ERROR, err);
    }
  }

  @Action
  [Actions.RESET_PASSWORD](user: IChangePassword) {
    ApiService.setHeader();
    const req = {
      password: {
        op: "Replace",
        value: user.password,
      },
    } as any;

    return ApiService.post(
      Endpoints.Users + "/forgot/reset?key=" + user.key,
      req
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error resetting the user password.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.LOG_USER_ACTIVITY](activity: IUserActivity) {
    ApiService.setHeader();
    return ApiService.post(Endpoints.UserActivity, activity as any)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error creating a user activity.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.SEARCH_USERS](param: IUserSearchParam) {
    if (!param) {
      return;
    }

    let query = "?";
    if (param.firstName) {
      query += "firstName=" + param.firstName;
    }

    if (param.lastName) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "lastName=" + param.lastName;
    }

    if (param.emailAddress) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "emailAddress=" + param.emailAddress;
    }

    if (param.distributionFull) {
      if (param.distributionFull !== "Any") {
        if (query.slice(-1) !== "?") {
          query += "&";
        }

        let webAccess = "true";
        if (param.distributionFull === "No") {
          webAccess = "false";
        }
        query += "distributionFull=" + webAccess;
      }
    }

    if (param.distributionNoLinks) {
      if (param.distributionNoLinks !== "Any") {
        if (query.slice(-1) !== "?") {
          query += "&";
        }

        let webAccess = "true";
        if (param.distributionNoLinks === "No") {
          webAccess = "false";
        }
        query += "distributionNoLinks=" + webAccess;
      }
    }

    if (param.canViewOlderDocuments) {
      if (param.canViewOlderDocuments !== "Any") {
        if (query.slice(-1) !== "?") {
          query += "&";
        }

        let webAccess = "true";
        if (param.canViewOlderDocuments === "No") {
          webAccess = "false";
        }
        query += "canViewOlderDocuments=" + webAccess;
      }
    }

    if (param.sugarCrmContactId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "sugarCrmContactId=" + param.sugarCrmContactId;
    }

    if (param.roleId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "roleId=" + param.roleId;
    }

    if (param.isWebAccessEnabled) {
      if (param.isWebAccessEnabled !== "Any") {
        if (query.slice(-1) !== "?") {
          query += "&";
        }

        let webAccess = "true";
        if (param.isWebAccessEnabled === "No") {
          webAccess = "false";
        }
        query += "isWebAccessEnabled=" + webAccess;
      }
    }

    if (param.isSubscribedToEnergyFacts) {
      if (param.isSubscribedToEnergyFacts !== "Any") {
        if (query.slice(-1) !== "?") {
          query += "&";
        }

        let subscribeddToEnergyFacts = "true";
        if (param.isSubscribedToEnergyFacts === "No") {
          subscribeddToEnergyFacts = "false";
        }
        query += "isSubscribedToEnergyFacts=" + subscribeddToEnergyFacts;
      }
    }

    if (param.ignoreDownloadLimit) {
      if (param.ignoreDownloadLimit !== "Any") {
        if (query.slice(-1) !== "?") {
          query += "&";
        }

        let ignoreDownloadLimit = "true";
        if (param.ignoreDownloadLimit === "No") {
          ignoreDownloadLimit = "false";
        }
        query += "ignoreDownloadLimit=" + ignoreDownloadLimit;
      }
    }

    if (param.isCrmUser) {
      if (param.isCrmUser !== "Any") {
        if (query.slice(-1) !== "?") {
          query += "&";
        }

        let crmUser = "true";
        if (param.isCrmUser === "No") {
          crmUser = "false";
        }
        query += "isCrmUser=" + crmUser;
      }
    }

    if (param.page != 1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }

    if (param.order) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.order;
    }

    ApiService.setHeader();
    return ApiService.get(Endpoints.Users, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_SEARCH_RESULT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error searching for users.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }
}
